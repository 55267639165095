<template>
  <section id="FieldMobile">
    <SideBarNavMobile subsectionTitle="The Field" />
    <div class="mobile-overlay"></div>
    <Photos />
    <Layout />
	<FieldEventPhotos />
  </section>
</template>

<script>
  import Photos from '@/components/sections/EventSpaces/Field/Photos'
  import Layout from '@/components/sections/EventSpaces/Field/Layout'
  import FieldEventPhotos from '@/components/sections/EventSpaces/Field/FieldEventPhotos'
  import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

  export default {
    name: "FieldMobile",
    components: {
      Photos,
      Layout,FieldEventPhotos,
      SideBarNavMobile
    }
  }
</script>

<style lang="scss" scoped>
  #FieldMobile {
    position: relative;
    width: 100vw;
    background-image: url('~@/assets/images/MobileBG/The-Field-BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin-top: -60px;
    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.83) 20%, rgba(0,0,0,0.92) 82%);
    }
  }
</style>
